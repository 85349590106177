import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from './app/ui';
import '@mantine/core/styles.css';
import '@mantine/dates/styles.css';

// У Bлада тоже здесь отключен линтер
// eslint-disable-next-line import/no-internal-modules
import './styles/globals.css';

import { CookiesProvider } from 'react-cookie';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <BrowserRouter>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </BrowserRouter>,
);
