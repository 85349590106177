import { Stack, NumberInput, Modal, Button, Flex } from '@mantine/core';
import { useUnit } from 'effector-react';

import { $formEditCommission, editOrgCommissionFx } from 'entities/org';
import { useForm } from 'effector-forms';

import { Dispatch, FormEvent, SetStateAction, useState } from 'react';
import { MyNumberInput } from 'shared/ui/input';
import { notifications } from '@mantine/notifications';
import { or } from 'ramda';
type PropsType = {
  orgId: string | undefined;
  setIsClickOpen: Dispatch<SetStateAction<boolean>>;
  isClickOpen: boolean;
};
export function ModalCommission({
  setIsClickOpen,
  isClickOpen,
  orgId,
}: PropsType) {
  const { 0: isLoading } = useUnit([editOrgCommissionFx.pending]);

  const {
    fields,
    submit,
    isDirty,
    values: formValues,
    eachValid,
  } = useForm($formEditCommission);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    submit();
    editOrgCommissionFx({
      orgId: Number(orgId),
      tipsPayInPercentageCommission: formValues.tipsPayInPercentageCommission,
      tipsPayOutScalarCommission: formValues.tipsPayOutScalarCommission,
      tipsPayOutPercentageCommission: formValues.tipsPayOutPercentageCommission,
      billPayOutPercentageCommission: formValues.billPayOutPercentageCommission,
      billPayInCardPercentageCommission:
        formValues.billPayInCardPercentageCommission,
      billPayInSBPPercentageCommission:
        formValues.billPayInSBPPercentageCommission,
    })
      .then(() => {
        notifications.show({
          autoClose: 2000,
          title: 'Успешно',
          message: 'Комиссия была успешно изменена',
          color: 'green',
        });
        setIsClickOpen(false);
      })
      .catch(() =>
        notifications.show({
          autoClose: 2000,
          title: 'Ошибка',
          message: 'Не удалось изменить комиссию',
          color: 'red',
        }),
      );
  };

  return (
    <Modal
      centered
      opened={isClickOpen}
      onClose={() => setIsClickOpen(false)}
      title='Сменить комиссию'>
      <form onSubmit={handleSubmit}>
        <Stack w='100%' gap={25}>
          <Stack gap={15}>
            <MyNumberInput
              suffix='%'
              thousandSeparator=' '
              decimalScale={2}
              label='Комиссия для оплаты чаевых'
              size='md'
              min={1}
              effectorField={fields.tipsPayInPercentageCommission}
            />
            <MyNumberInput
              suffix='₽'
              thousandSeparator=' '
              decimalScale={2}
              label='Комиссия для вывода средств'
              size='md'
              min={1}
              effectorField={fields.tipsPayOutScalarCommission}
            />
            <MyNumberInput
              suffix='%'
              thousandSeparator=' '
              decimalScale={2}
              label='Комиссия для вывода средств в процентах'
              size='md'
              min={1}
              effectorField={fields.tipsPayOutPercentageCommission}
            />
            <MyNumberInput
              suffix='%'
              thousandSeparator=' '
              decimalScale={2}
              label='Комиссия на оплату счета картой'
              size='md'
              min={1}
              effectorField={fields.billPayInCardPercentageCommission}
            />
            <MyNumberInput
              suffix='%'
              thousandSeparator=' '
              decimalScale={2}
              label='Комиссия на оплату счета сбп'
              size='md'
              min={1}
              effectorField={fields.billPayInSBPPercentageCommission}
            />
            <MyNumberInput
              suffix='%'
              thousandSeparator=' '
              decimalScale={2}
              label='Комиссия на вывод средств с оплаты счетов'
              size='md'
              min={1}
              effectorField={fields.billPayOutPercentageCommission}
            />
          </Stack>

          <Button type='submit' loading={isLoading} radius={10}>
            Отправить
          </Button>
        </Stack>
      </form>
    </Modal>
  );
}
