import { createStore, sample } from 'effector';
import { adminSignInFx, changeDataFx } from './effects';
import { appReset } from 'entities/events';
import { TipsApiResType } from 'shared/api';

import { AdminEntityType } from 'entities/entities-types';
import { $$adminGetOneOwn } from './attaches';

export const $adminSignIn = createStore<TipsApiResType<void> | null>(null)
  .on(adminSignInFx.doneData, (state, payload) => {
    return payload.data;
  })
  .reset(appReset);

sample({
  clock: changeDataFx.done,
  source: $$adminGetOneOwn.$store.map((s) => s.res?.data),
  // fn: (src, clk) => {
  //   return {
  //   };
  // },
  target: $$adminGetOneOwn.attachedFx,
});
