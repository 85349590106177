import { useUnit } from 'effector-react';
import {
  $$adminGetFraudArr,
  $$adminGetFraudInfo,
} from 'entities/admin/attaches-fraud';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './FraudDetails.css';
import { Flex, Loader, LoadingOverlay, ScrollArea } from '@mantine/core';

const FraudDetails = () => {
  const { fraudTxId } = useParams();
  const { 0: transaction, 1: isLoading } = useUnit([
    $$adminGetFraudInfo.$store.map((s) => s.res?.data),
    $$adminGetFraudInfo.attachedFx.pending,
  ]);

  useEffect(() => {
    if (fraudTxId) {
      $$adminGetFraudInfo.argEditEvent({
        fraudTransactionIds: [Number(fraudTxId)],
        currentPage: 1,
        itemsPerPage: 100,
      });
    }
  }, [fraudTxId]);

  if (isLoading) {
    return (
      <Flex justify='center' align='center'>
        <Loader />
      </Flex>
    );
  }

  return (
    <div className='fraud-details'>
      <h1 className='fraud-details__title'>Fraud Transaction Details</h1>
      <ScrollArea maw='100vw'>
        {transaction ? (
          <div>
            {/* Main Info Section */}
            <section className='fraud-details__section'>
              <h2 className='fraud-details__subtitle'>Main Information</h2>
              <table className='fraud-details__table'>
                <thead>
                  <tr>
                    <th>fraudTxId</th>
                    <th>Description</th>
                    <th>Rule Name</th>
                    <th>Subtransaction UUID</th>
                    <th>Transaction UUID</th>
                    <th>Tx Metadata ID</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{transaction[0]?.fraudTx?.fraudTxId}</td>
                    <td>{transaction[0]?.fraudTx?.description}</td>
                    <td>{transaction[0]?.fraudTx?.ruleName}</td>
                    <td>{transaction[0]?.fraudTx?.subtransactionUUID}</td>
                    <td>{transaction[0]?.fraudTx?.transactionUUID}</td>
                    <td>{transaction[0]?.fraudTx?.txMetadataId}</td>
                  </tr>
                </tbody>
              </table>
            </section>

            {/* Transaction Section */}
            <section className='fraud-details__section'>
              <h2 className='fraud-details__subtitle'>Transactions</h2>
              {transaction[0]?.transaction && (
                <table className='fraud-details__table'>
                  <thead>
                    <tr>
                      <th>TxId</th>
                      <th>Action</th>
                      <th>Status</th>
                      <th>Gross Amount</th>
                      <th>Net Amount</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Method</th>
                      <th>Bank Ref</th>
                      <th>OrderId</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{transaction[0]?.transaction.txId}</td>
                      <td>{transaction[0]?.transaction.action}</td>
                      <td>{transaction[0]?.transaction.status}</td>
                      <td>{transaction[0]?.transaction.grossAmount}</td>
                      <td>{transaction[0]?.transaction.netAmount}</td>
                      <td>
                        {new Date(
                          transaction[0]?.transaction.createdAt,
                        ).toLocaleString()}
                      </td>
                      <td>
                        {new Date(
                          transaction[0]?.transaction.updatedAt,
                        ).toLocaleString()}
                      </td>
                      <td>{transaction[0]?.transaction.method}</td>
                      <td>{transaction[0]?.transaction.bankRef}</td>
                      <td>{transaction[0]?.transaction.orderId}</td>
                    </tr>
                  </tbody>
                </table>
              )}
              <h2 className='fraud-details__subtitle'>Subtransactions</h2>
              {transaction[0]?.transaction?.subtransactions.length ? (
                <table className='fraud-details__table'>
                  <thead>
                    <tr>
                      <th>Subtransaction ID</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Gross Amount</th>
                      <th>Net Amount</th>
                      <th>Created At</th>
                      <th>Updated At</th>
                      <th>Subtransaction UUID</th>
                      <th>Bank Commission</th>
                      <th>Child Transaction ID</th>
                      <th>Out Commission</th>
                      <th>Total Commission</th>
                      <th>Transaction ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {transaction[0]?.transaction.subtransactions.map((sub) => (
                      <tr key={sub.subtransactionId}>
                        <td>{sub.subtransactionId}</td>
                        <td>{sub.type}</td>
                        <td>{sub.status}</td>
                        <td>{sub.grossAmount}</td>
                        <td>{sub.netAmount}</td>
                        <td>{new Date(sub.createdAt).toLocaleString()}</td>
                        <td>{new Date(sub.updatedAt).toLocaleString()}</td>
                        <td>{sub.subtransactionUUID}</td>
                        <td>{sub.bankCommission}</td>
                        <td>{sub.childTransactionId}</td>
                        <td>{sub.outCommission}</td>
                        <td>{sub.totalCommission}</td>
                        <td>{sub.transactionId}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p>No subtransactions available.</p>
              )}
            </section>

            {/* User Information Section */}
            <section className='fraud-details__section'>
              <h2 className='fraud-details__subtitle'>User Information</h2>
              <table className='fraud-details__table'>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Patronymic</th>
                    <th>Email</th>
                    <th>Status</th>
                    <th>Birthday Date</th>
                    <th>Role</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Deleted At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{transaction[0]?.user?.firstname}</td>
                    <td>{transaction[0]?.user?.lastname}</td>
                    <td>{transaction[0]?.user?.patronymic}</td>
                    <td>{transaction[0]?.user?.email}</td>
                    <td>{transaction[0]?.user?.status}</td>
                    <td>
                      {new Date(
                        transaction[0]?.user?.birthdayDate,
                      ).toLocaleDateString()}
                    </td>
                    <td>{transaction[0]?.user?.role}</td>
                    <td>
                      {new Date(
                        transaction[0]?.user?.createdAt,
                      ).toLocaleString()}
                    </td>
                    <td>
                      {new Date(
                        transaction[0]?.user?.updatedAt,
                      ).toLocaleString()}
                    </td>
                    <td>
                      {new Date(
                        transaction[0]?.user?.deletedAt,
                      ).toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>

            {/* Organization Information Section */}
            <section className='fraud-details__section'>
              <h2 className='fraud-details__subtitle'>
                Organization Information
              </h2>
              <table className='fraud-details__table'>
                <thead>
                  <tr>
                    <th>Organization Name</th>
                    <th>INN</th>
                    <th>Email</th>
                    <th>Phone</th>
                    <th>Status</th>
                    <th>Balance ID</th>
                    <th>Commission Rate</th>
                    <th>Jurisdiction Name</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{transaction[0]?.org?.name}</td>
                    <td>{transaction[0]?.org?.inn}</td>
                    <td>{transaction[0]?.org?.email}</td>
                    <td>{transaction[0]?.org?.phoneNumber}</td>
                    <td>{transaction[0]?.org?.status}</td>
                    <td>{transaction[0]?.org?.balanceId}</td>
                    <td>
                      {transaction[0]?.org?.tipsPayInPercentageCommission}%
                    </td>
                    <td>{transaction[0]?.org?.juridicialPersonName}</td>
                  </tr>
                </tbody>
              </table>
            </section>

            {/* Metadata Section */}
            <section className='fraud-details__section'>
              <h2 className='fraud-details__subtitle'>Transaction Metadata</h2>
              <table className='fraud-details__table'>
                <thead>
                  <tr>
                    <th>Tx Action</th>
                    <th>Payer Full Name</th>
                    <th>Payer Birthday</th>
                    <th>Payer Phone</th>
                    <th>Payer Email</th>
                    <th>Recipient Full Name</th>
                    <th>Recipient Birthday</th>
                    <th>Recipient Phone</th>
                    <th>Recipient Email</th>
                    <th>Is Completed</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{transaction[0]?.metadata?.txAction}</td>
                    <td>{transaction[0]?.metadata?.payerFullName}</td>
                    <td>{transaction[0]?.metadata?.payerBirthday}</td>
                    <td>{transaction[0]?.metadata?.payerPhoneNumber}</td>
                    <td>{transaction[0]?.metadata?.payerEmail}</td>
                    <td>{transaction[0]?.metadata?.recipientFullName}</td>
                    <td>{transaction[0]?.metadata?.recipientBirthday}</td>
                    <td>{transaction[0]?.metadata?.recipientPhoneNumber}</td>
                    <td>{transaction[0]?.metadata?.recipientEmail}</td>
                    <td>
                      {transaction[0]?.metadata?.isCompletedStatus
                        ? 'Completed'
                        : 'Not Completed'}
                    </td>
                    <td>
                      {new Date(
                        transaction[0]?.metadata?.createdAt,
                      ).toLocaleString()}
                    </td>
                    <td>
                      {new Date(
                        transaction[0]?.metadata?.updatedAt,
                      ).toLocaleString()}
                    </td>
                  </tr>
                </tbody>
              </table>
            </section>
          </div>
        ) : (
          <p>Транзакция недоступна.</p>
        )}
      </ScrollArea>
    </div>
  );
};

export default FraudDetails;
