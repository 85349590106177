import { createStoreAndEventAndAttachForEffect } from 'entities/effects';
import { adminGetFraudArr, adminGetFraudInfo } from './effects';
import { ENDPOINTS_ADMIN } from './endpoints';

export const $$adminGetFraudInfo = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ADMIN.GET_FRAUD.req,
  typeof ENDPOINTS_ADMIN.GET_FRAUD.res
>({
  initialReq: { currentPage: 1, itemsPerPage: 100 },
  initialFx: adminGetFraudInfo,
});

export const $$adminGetFraudArr = createStoreAndEventAndAttachForEffect<
  typeof ENDPOINTS_ADMIN.GET_FRAUD_ARR.req,
  typeof ENDPOINTS_ADMIN.GET_FRAUD_ARR.res
>({
  initialReq: { txIds: [] },
  initialFx: adminGetFraudArr,
});
