import { Stack } from '@mantine/core';
import { Navbar } from 'features/navbar';
import { split } from 'ramda';
import { FC, ReactNode, useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation } from 'react-router';

export const PageLayout: FC<{ children: ReactNode }> = ({ children }) => {
  const [cookies, setCookie] = useCookies(['authorized']);
  const isAuthorized = !!cookies.authorized; // Проверка наличия куки

  return (
    <Stack style={{ position: 'relative' }}>
      <Stack>
        {isAuthorized && <Navbar />}
        <Stack p={{ base: 16, md: 20 }}>{children}</Stack>
      </Stack>
    </Stack>
  );
};
