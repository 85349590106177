import {
  Table,
  Container,
  TextInput,
  Button,
  Group,
  Flex,
  Loader,
} from '@mantine/core';
import { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { $$adminGetFraudInfo } from 'entities/admin/attaches-fraud';
import { useUnit } from 'effector-react';
import { DatePickerInput } from '@mantine/dates';
import { debounce } from 'lodash';

const FraudPage = () => {
  const navigate = useNavigate();

  const { 0: fraudData, 1: isLoading } = useUnit([
    $$adminGetFraudInfo.$store.map((s) => s.res?.data),
    $$adminGetFraudInfo.attachedFx.pending,
  ]);
  const [filters, setFilters] = useState({
    fraudId: '',
    ruleName: '',
    startDate: null as Date | null,
    endDate: null as Date | null,
  });

  const handleFilterChange = (field: string, value: any) => {
    setFilters((prev) => ({ ...prev, [field]: value }));
  };

  // Оборачиваем debounce в useCallback, чтобы он не перезаписывался каждый раз
  const debouncedFetchData = useCallback(
    debounce(() => {
      $$adminGetFraudInfo.argEditEvent({
        currentPage: 1,
        itemsPerPage: 1000,
        createdAtMin: filters.startDate
          ? filters.startDate.toISOString()
          : undefined,
        createdAtMax: filters.endDate
          ? filters.endDate.toISOString()
          : undefined,
        fraudTransactionIds: filters.fraudId ? [Number(filters.fraudId)] : [],
        ruleNames: filters.ruleName ? [filters.ruleName] : [],
      });
    }, 700),
    [filters.endDate, filters.startDate, filters.fraudId, filters.ruleName], // Зависящий от filters
  );

  useEffect(() => {
    debouncedFetchData();
    // Очистка функции debounce при размонтировании компонента
    return () => {
      debouncedFetchData.cancel();
    };
  }, [filters.endDate, filters.startDate, filters.fraudId, debouncedFetchData]);

  const handleRowClick = (fraudTxId: number) => {
    navigate(`/fraud-details/${fraudTxId}`);
  };

  const formatDate = (isoString: string) => {
    const date = new Date(isoString);
    return new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    }).format(date);
  };

  const rows = fraudData?.map((item: any) => {
    const { fraudTx, user, org, transaction } = item;

    return (
      <tr
        key={fraudTx.fraudTxId}
        onClick={() => handleRowClick(fraudTx.fraudTxId)}
        style={{ cursor: 'pointer' }}>
        <td style={{ padding: '5px 10px', border: '1px solid #ddd' }}>
          {fraudTx.fraudTxId}
        </td>
        <td style={{ padding: '5px 10px', border: '1px solid #ddd' }}>
          {user.userId}
        </td>
        <td style={{ padding: '5px 10px', border: '1px solid #ddd' }}>
          {user.firstname} {user.lastname}
        </td>
        <td style={{ padding: '5px 10px', border: '1px solid #ddd' }}>
          {org.name}
        </td>
        <td style={{ padding: '5px 10px', border: '1px solid #ddd' }}>
          {fraudTx.ruleName}
        </td>
        <td style={{ padding: '5px 10px', border: '1px solid #ddd' }}>
          {formatDate(transaction.createdAt)}
        </td>
      </tr>
    );
  });

  return (
    <Container>
      {/* Фильтры */}
      <Group mb='md' align='flex-end'>
        <TextInput
          placeholder='Введите Fraud ID'
          label='Fraud ID'
          value={filters.fraudId}
          onChange={(e) => handleFilterChange('fraudId', e.target.value)}
        />
        <TextInput
          placeholder='Введите правило'
          label='Фильтр по правилу'
          value={filters.ruleName}
          onChange={(e) => handleFilterChange('ruleName', e.target.value)}
        />
        <DatePickerInput
          label='Дата с'
          miw={100}
          placeholder='Выберите дату'
          value={filters.startDate}
          onChange={(date) => handleFilterChange('startDate', date)}
        />
        <DatePickerInput
          label='Дата по'
          placeholder='Выберите дату'
          miw={100}
          value={filters.endDate}
          onChange={(date) => handleFilterChange('endDate', date)}
        />
        <Button
          onClick={() =>
            setFilters({
              fraudId: '',
              ruleName: '',
              startDate: null,
              endDate: null,
            })
          }>
          Сбросить фильтры
        </Button>
      </Group>

      {/* Таблица */}
      <Table>
        <thead>
          <tr>
            <th>Фрод ID</th>
            <th>Юзер ID</th>
            <th>ФИО</th>
            <th>Орг.</th>
            <th>Правило</th>
            <th>Дата</th>
          </tr>
        </thead>
        {!isLoading && <tbody>{rows}</tbody>}
      </Table>
      {isLoading && (
        <Flex justify='center' align='center'>
          <Loader />
        </Flex>
      )}
    </Container>
  );
};

export default FraudPage;
