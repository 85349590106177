import {
  Flex,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Title,
  TextInput,
  ScrollArea,
} from '@mantine/core';
import { useUnit } from 'effector-react';
import { OrgEntityType, StatusValueType } from 'entities/entities-types';
import { $$getManyOrg } from 'entities/org';
import { groupBy } from 'ramda';
import { useEffect, useState } from 'react';
import { ChevronRight } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import { orgStatusColor, orgStatusTranslate } from 'shared/lib';

export const Organizations = () => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState(''); // Состояние для поискового запроса

  const handleButtonClick = (orgId: number) =>
    navigate(`/profile-org/${orgId}`);

  const { 0: orgs, 1: isLoading } = useUnit([
    $$getManyOrg.$store.map((s) => s.res?.data),
    $$getManyOrg.attachedFx.pending,
  ]);

  useEffect(() => {
    $$getManyOrg.argEditEvent({
      currentPage: 1,
      itemsPerPage: 10000,
    });
  }, []);

  // Группируем организации по статусам
  const groupedOrgs = groupBy((org) => org.status, orgs || []);

  const orgStatus: StatusValueType[] = [
    'received',
    'considering',
    'canceled',
    'denied',
    'approved',
    'removed',
    'suspended',
    'blocked',
  ];

  // Функция для фильтрации организаций по имени
  const filterOrgsByName = (
    orgs: OrgEntityType[] | undefined,
    query: string,
  ) => {
    if (!query) return orgs; // Если нет запроса, возвращаем все организации
    return orgs?.filter((org) =>
      org?.name.toLowerCase().includes(query.toLowerCase()),
    ); // Фильтруем по названию
  };

  return (
    <Stack>
      <Stack mt={60}>
        <LoadingOverlay h='100vh' visible={isLoading} />
        <Title ta='center' order={3}>
          Все организации
        </Title>
        <Text mb={60} ta='center'>
          Всего организаций - {orgs?.length}
        </Text>
        {/* Добавляем текстовое поле для поиска */}
        <TextInput
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          placeholder='Поиск по названию'
          mb={20}
        />

        <ScrollArea w='90vw'>
          <Flex gap={20}>
            {orgStatus.map((status) => (
              <Stack key={status}>
                <Title c={orgStatusColor(status)} order={4}>
                  {orgStatusTranslate(status)}
                </Title>
                {groupedOrgs[status] ? (
                  filterOrgsByName(groupedOrgs[status], searchQuery)?.map(
                    (org) => (
                      <Paper
                        w={200}
                        onClick={() => handleButtonClick(org.orgId)}
                        style={{ cursor: 'pointer' }}
                        key={org.orgId}
                        radius={10}
                        shadow='xs'
                        p='md'>
                        <Flex align='center' justify='space-between'>
                          <Stack gap={5}>
                            <Text fw='bold' fz={18}>
                              {org.name}
                            </Text>
                          </Stack>
                          <ChevronRight opacity={0.5} />
                        </Flex>
                      </Paper>
                    ),
                  )
                ) : (
                  <Text c='gray'>Еще нет организаций</Text>
                )}
              </Stack>
            ))}
          </Flex>
        </ScrollArea>
      </Stack>
    </Stack>
  );
};
