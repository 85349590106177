import { T, always, cond, equals } from 'ramda';

export const orgStatusTranslate = (stays: string | undefined) => {
  return cond([
    [equals('considering'), always('На рассмотрении')],
    [equals('canceled'), always('Заявка отменена')],
    [equals('denied'), always('Отказано')],
    [equals('received'), always('В обработке')],
    [equals('removed'), always('Организация удалена')],
    [equals('approved'), always('Организация одобрена')],
    [equals('blocked'), always('Организация заблокирована')],
    [equals('suspended'), always('Организация заморожена')],
    [T, always('')],
  ])(stays ?? '');
};

export const orgRoleTranslate = (stays: string | undefined) => {
  return cond([
    [equals('admin'), always('админ')],
    [equals('moderator'), always('Модератор')],
    [T, always('')],
  ])(stays ?? '');
};
